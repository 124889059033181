@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
  font-family: "Oswald", sans-serif 'Montserrat', sans-serif;
}

/* a {
  color: #ffffff !important;
  text-decoration: none;
} */
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  background-color: #1a1a1a;
}
.header .logo img {
  max-height: 40px;
}
.navbar {
  padding: 0;
}

.navbar ul {
  list-style: none;
}

.navbar li {
  position: relative;
}
.navbar a,
.navbar a:focus {
  padding-top: 15px;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.navbar-toggler-icon {
  background-color: red;
  width: 34px;
  height: 34px;
}

.navbar-toggler{
  padding: 3.5px;
  color: rgb(232, 38, 38);
}

/*-------------home---------*/
.bg-banner{
  /* background-image: url('../images/bg-1.png'); */
  background-image: url('../images/ai/Slider@4x.png');
  background-color: #000000;
  max-height: 100vh;
  /* height: 100vh; */
  /* padding-top: 10rem; */
  padding-bottom: 200px;
  background-repeat: no-repeat;
  background-size:cover
}

.banner-text-1{
  font-size: 34px;
  font-family: "Oswald", sans-serif;
  color:#ffffff;
  border-color: #ffffff;
}
.banner-text-2{
  font-size: 79px;
  font-family: "Oswald", sans-serif;
  color:#ffffff
}

.hero-btn {
  background-color: #ff0000;
  outline: none !important;
  font-size: 32px !important;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  border-radius: 4px;
}
/*-------------home---------*/
/* about start */
.about-top{
  padding-top:5rem;
}
.about-text h5{
  font-size: 60px;
  font-family: "Oswald", sans-serif;
  color: #FF0000;
  margin-bottom: 0px;
}
.about-text h6{
  font-size: 60px;
  font-family: "Oswald", sans-serif;
  color: #000000;
  line-height: 50px;
}
.about-text p{
  font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    line-height: 28px;
    text-align: justify;
    font-weight: 600 !important;
}

.about-image {
  padding-top: 35rem;
}

.facts-figures{
  padding-top: 4rem;
}

.counter-head{
  background-color: #FF0000;
  display: inline-block;
  font-size: 36px !important;
  font-family: "Oswald", sans-serif;
  font-weight: 600 !important;
  /* margin-left: 40%; */
} 
.counter{
  line-height: 160px;
}
.counter_value{
  font-size: 150px;
  font-family: "Oswald", sans-serif;
  color: #000000;
}
.counter_text{
  color: #808080;
  font-size: 27px;
  font-style: normal;
}
/* about end */

/* ground start  */
.bg-banner2{
  background-image: url(../images/playground.png);
  height: 100rem;
  background-repeat: no-repeat;
  background-size: cover;
  
}

/* Initially, hide the overlay */
.hover-overlay {
  background-color: rgba(165, 50, 50, 0); /* Transparent background */
  border-radius: 0;
  transition: background-color 1s ease-in-out; /* Transition on background with 500ms delay */
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0) 30%, #000 120%);

}

/* When hovering over the parent element, show the overlay */
.card:hover .hover-overlay {
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0) 30%, #ff0000 100%);
}


.play_ground_top_text h6{
  font-size: 60px;
  font-family:"Oswald", sans-serif;
}
.play_ground_img_text1{
  background-color: #FF0000;
  font-family:"Oswald", sans-serif;
  width: 8rem;
  text-align: center;
  font-size: 14px;
}
.play_ground_img_text2{
  font-size: 36px;
  font-family:"Oswald", sans-serif;
  width: 15rem;
}


.ground-card{
  height: 320px;
}

.card img{
  border-radius: 1px;
  outline-color: #090909;
}
/* play ground card end */
 /* feature start */
.feature_card{
  margin-top: 50rem;
}
.feature_top_text{
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  /* width:600px; */
}
.feature_card_bg_color{
  background-color: #FF0000;
}
.feature_img_text{
  font-size: 34px;
  font-family:"Oswald", sans-serif;
  width: 12rem;
}

.feature-img-overlay {
  background-color: rgba(23, 23, 23, 0.801); /* Black background with 50% opacity */
  cursor: pointer;
}

.feature-img-overlay:hover {
  background-color:transparent;
}

.feature .card-img-top:hover {
  transform: scale(1.2);
}
 /* feature end */

/* news & event start */
.bg-banner3{
  /* background-image: url(../images/banner3.png);
  height: 145rem; */
  /* background-repeat: no-repeat;
  background-size: cover; */
}

.news_event_card{
  margin-top: 145rem;
}


.news_event_card-content{
  margin-top: 27rem;
}

.news_event_top_text{
  font-size: 60px;
  font-family:"Oswald", sans-serif;
  color: #FF0000;
  margin-bottom: 4rem;
}
.news_event_text1{
  background-color: #FF0000;
  font-size: 14px;
  font-family:"Oswald", sans-serif;
}
.news_event_text2{
  font-size: 26px;
  font-family:"Oswald", sans-serif;
  width: 12rem;
}
.news_event_text3{
  font-size: 12px;
  color: #009245;
  font-family:"Oswald", sans-serif;
}
.news_event_btn{
  font-size: 24px;
  font-family:"Oswald", sans-serif;
  margin-top: 5rem;
  background-color: #FF0000;
  padding: 3px 0;
}
/* news & and event end */

/* client section start */
.client_card{
  margin-top: 4rem;
}
.client_card_bg{
  background-color:#4D4D4D;
  padding: 50px 50px 0px !important;
}
.client_top_text{
  /* padding-top: 132rem; */
  font-size: 45px;
  font-family:"Oswald", sans-serif;
}
.client_top_text_col{
  color: #D50000;
}
.client_card_text1{
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
}
.client_card_text2{
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
}
.client_btn{
  font-family:"Oswald", sans-serif;
  font-size: 24px;
  background-color: #FF0000;
}
/* client section end */
 /* app start */
 .app_sec{
  margin-top: 110rem;
 }
.app_text_top{
  font-size: 60px;
  font-family:"Oswald", sans-serif;
}
.text_color{
  color: #FF0000;
}
/* app end */


/* map start */
.sub-bac
{
  background:#333333;
  box-sizing: border-box;
}
.sub-text{
  font-family: "Oswald", sans-serif;
  font-size: 40px;
}

.subscribe{
  padding: 70px 0;
}

.form-subscribe
{
  max-width:850px;
  margin:0 auto
}
.subs-input-bg
{
  background-color:hsla(0,0%,100%,.8);
}
.form-subscribe .form-control.focus,.form-subscribe .form-control:focus
{
  z-index:2;
  background-color:hsla(0,0%,100%,.8)
}
.form-subscribe .btn1
{
  background:#D50000;
  border-color:#D50000;
}
.form-control.input-lg.rounded-start-4:focus {
  border-color: #ced4da;
  box-shadow: none; 
}
.map-logo{
margin-top: 17rem;
}
.contact .map iframe {
  width: 100%;
  height: 400px;
}
/* map end */
/* footer start */
.foot-bac{
  background-color: #333333;
  font-family:'Montserrat', sans-serif ;
}
.foot-text-left{
  line-height: 1.9;
  font-size: 11px;
 font-family:'Montserrat', sans-serif ;
}
.foot-text-right{
  font-size: 15px;
  font-weight: 600;
 font-family:'Montserrat', sans-serif ;
}
.foot-bor{
  background-color: #262626;
  letter-spacing:8px;
  font-size: 13px;
}
/* footer end */


/* media query  start*/
@media (max-width: 768px) {
  .bg-banner{
    padding-top: 6rem;
  }
  .about-text {
    width: 100%;
  }
  .about-text h6{
    font-size: 40px;
  }
  .ground-card{
    height: 100px;
  }

  .playground{
    margin-top: 0rem;
  }

  .feature_card {
    margin-top: 128rem;
  }

  .play_ground_text{
    width: 100%;
  }

  .news_event_card{
    margin-top: 210rem;
  }

  .news_event_card-content{
    margin-top: 70rem;
  }

  .client_card{
    margin-top: 110rem;
  }

  .news_event_top_text{
    margin-bottom: 4rem;
  }

  .bg-banner{
    height: 100rem;
  }

  .bg-banner3 {
      height: 260rem;
  }

  .client_top_text{
    font-size: 37px;
    padding-bottom: 10px;
  }

  .app_sec{
    margin-top: 215rem;
  }

  .app_text_top {
    width: 100%;
  }

  .form-subscribe
  {
    max-width:550px;
  }
  
  .sub-text {
    font-size: 18px;
    font-style: normal;
  }

  .subs-input-bg{
    font-size: 18px;
  }

}

@media (min-width: 769px) {
  .bg-banner{
    padding-top: 11rem;
  }
  .about-text {
    width: 50%;
  }

  .ground-card{
    height: 200px;
  }

  .feature_top_text{
    width: 80%;
  }

  .play_ground_text{
    width: 50%;
  }

  .app_text_top{
    width: 50%;
  }

  .subs-input-bg{
    font-size: 30px;
  }

  .news_event{
    /* margin-top: 10rem; */
  }

}

@media (max-width: 576px) {
  .counter_value{
    font-size: 50px;
  }
  .counter_text{
    font-size: 10px;
  }
  .counter{
    line-height: 80px;
  }

  .feature_top_text{
    width: 80%;
  }

  .subscribe{
    padding: 20px 0;
    width: 22rem;
  }

  .foot-bor{
    letter-spacing:5px;
    font-size: 8px;
  }

}

@media (min-width: 577px) {
  
  .subscribe{
    padding: 70px 0;
    width: 80rem;
  }

  .foot-bor{
    letter-spacing: 8px;
    font-size: 13px;
  }

}

@media (min-width: 576px) and (max-width: 992px) {
  .counter_value{
    font-size: 100px;
  }
  .counter_text{
    font-size: 18px;
  }

  .play_ground_img_text1{
    width: 8rem;
    font-size: 12px;
  }

  .play_ground_img_text2{
    font-size: 16px;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  .playground{
    margin-top: 5rem;
  }
}

@media (min-width: 1399.98px) { 

  .bg-banner {
    /* height: 123rem; */
  }
  .about-top{
    /* padding-top:65rem; */
  }

  .facts-figures{
    /* padding-top: 25rem; */
  }

  .feature_card{
    margin-top: 68rem;
  }

  .playground{
    /* margin-top: 34rem; */
  }

  .news_event{
    /* margin-top: 25rem; */
  }

  .news_event_card-content{
    margin-top: 34rem;
  }

  .client_card{
    margin-top: 14rem;
  }

}



/* updated by del */

.counter{
  flex:1
}

.header a {
  color: #ffffff !important;
  text-decoration: none;
}

.delGround .card{
  padding: 0px;
}

.play_ground_top_text h6{
  color: #ffffff;
}

.react-date-picker__wrapper{
  padding: 20px;
  border-radius: 10px;
  background: #FAFAFA;
  border: none;
}

.css-13cymwt-control{
  background: #FAFAFA !important;
  border: none !important;
}

.customLoder {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.customLoder:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.customLoder:not(:required) {
  /* hide "customLoder..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.customLoder:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}


.banner-text-2{
  max-width: 500px;
}

@media (min-width: 1399.98px){
  .bg-banner{
    background-size: contain;
    background-position: center;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-left: 100vw solid transparent;
/*   border-right: 50px solid transparent; */
  border-bottom: 150px solid white; /* Change color as needed */
  position: absolute;
  bottom: 0;
}

.outlined-text {
  color: #fff; /* Set the main color of the text */
  text-shadow:
    -1px -1px 0 #c9000f, /* Shadow on the top and left side */
    1px -1px 0 #c9000f, /* Shadow on the top and right side */
    -1px 1px 0 #c9000f, /* Shadow on the bottom and left side */
    1px 1px 0 #c9000f; /* Shadow on the bottom and right side */
}


.playground-bg-text{
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 0px;
  font-size: 230px;
  color: #c9000f;
  text-align: center;
  z-index: -1;
  font-weight: 600;
}

.feature-bg-text{
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 0px;
  font-size: 230px;
  /* color: #c9000f; */
  text-align: center;
  z-index: -1;
  font-weight: 600;
  opacity: 0.2;
}


.feature-section .card, .news_event .card{
  padding: 0px;
}

.feature_img_text{
  margin-bottom: 0px;
  text-transform: uppercase;
}

.fav-icon.active {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #FFFFFF !important;
}

.blog-sidebar .card .latest-posts li{
  display: block;
}

.overflow-100{
  width: 100%;
  overflow: hidden;
}

.ground-section{
  padding-top:100px;
  padding-bottom:150px; 
  background-color:#a7000d; 
  position:relative;
  z-index:0
}

.feature-section{
  padding-top:100px;
  padding-bottom:150px; 
  position:relative;
  z-index:0
}

.news-section{
  padding-top:100px;
  padding-bottom:220px; 
  position:relative;
  z-index:0;

  background-color: #000000;
}

.news-bg-text{
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 0px;
  font-size: 230px;
  /* color: #c9000f; */
  text-align: center;
  z-index: -1;
  font-weight: 600;
  opacity: 0.3;
  color: #000000;
  text-shadow:
    -1px -1px 0 #ddd, /* Shadow on the top and left side */
    1px -1px 0 #ddd, /* Shadow on the top and right side */
    -1px 1px 0 #ddd, /* Shadow on the bottom and left side */
    1px 1px 0 #ddd; /* Shadow on the bottom and right side */
}

.news-section .card{
  padding: 0px;
}

.grounds-filter{
  box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.25);
  padding:15px;
  border-radius: 10px;
}

.filterDate .react-date-picker{
  width: 100%;
}

.booked{
  color: #ddd;
}

.book-avaliable{
  /* padding: 1px;
  padding-right: 8px;
  padding-left: 8px; */
  /* border-radius: 20px; */
  cursor: pointer;
  text-align: center;
}

.book-avaliable:hover{
  background-color: #ff0000;
  color: #fff;
}

.floatingImage{

  animation: float 6s ease-in-out infinite;

}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-30px);
	}
	100% {
		transform: translatey(0px);
	}
}

.SUBSCRIBE{
  background-image: url(./../images/subbg2.jpg);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
}

.timeslot-calender td{
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-align: center !important;
}

.client_card_text1{
  height: 150px;
  text-overflow: hidden;
}

.bottom-section{
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  z-index: 9999;
}

.bottom-section-nav{
  height: 60px;
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-size: 12px;
}

.single-ground-image{
  max-width: 400px;
}

.featured-venues-item{
  margin: 10px 0px;
}

.playstore-link-image{
  height: 120px;
}


.mobile-header{
  height: 50px;
  background-color: #1a1a1a;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.mobile-header .logo{
  height: 35px;
}


@media (min-width: 993px) {

  .bottom-section{
    display: none;
  }

  .mobile-header{
    display: none;
  }
  
}



@media (max-width: 767px){
  .single-ground-image{
    max-width: 100%;
  }

  .subscribe .input-group-btn{
    display: flex;
  }
}


@media (max-width: 992px){

  .navbar{
    display: none;
  }

  
  .floatingImage{
    display: none;
  }

  

}

a.nav-link.active{
  color: #ff0000 !important;
}



.header a.nav-link:hover{
  color: #ff0000 !important;
}

.bottom-section-nav:hover,  .bottom-section-nav.active{
  color: #ff0000;
}


.social-btn{
  height: 35px;
    width: 35px;
    border-radius: 10px;
    background-color: #DDD;
    color: #c9000f;
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.ellips-title{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.home-blog img{
  height: 250px;
  object-fit: cover;
}

.venue-coach-details .accordion .accordion-item li i{
  color: #c9000f;
  margin-right: 5px;
}

.aboutus-info .vision-mission .mission-bg{
  background-color: #fff;

}

.aboutus-info .vision-mission .mission-bg, .aboutus-info .vision-mission .mission-bg h2{
  color: #000;
}

.aboutus-info .vision-mission .mission-bg:hover{
  background-color:#c9000f;
  color: #fff;
}

.aboutus-info .vision-mission .mission-bg:hover h2{
  color: #fff;

}

.aboutus-info .vision-mission .mission-bg:hover h2{
  color: #fff;

}